import React from "react"

import Layout from "../components/layout"
import Content from "../components/Content"
import Footer from "../components/Footer"
import Background from "../components/Background"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Look at All These Things" />
    <Background />
    <Content />
    <Footer />
  </Layout>
)

export default IndexPage

import React from "react"
import Cursor from "../images/Cursor_01.svg"
import Popup from "./Popup"
import {getMousePosition} from "../utils/"

import "./content.scss"

class Content extends React.Component {

  constructor() {
    super();
    this.state = {
      popup: false,
      mounted: false,
    };
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {

    this.linkRef.addEventListener("mouseenter", this.mouseEnter);
    this.linkRef.addEventListener("mouseleave", this.mouseLeave);

    setTimeout(() => {
      this.setState({
        mounted: true,
      })
    }, 500)
  }

  componentWillUnmount() {
    this.linkRef.removeventListener("mouseenter", this.mouseEnter);
    this.linkRef.removeventListener("mouseleave", this.mouseLeave);
  }

  mouseEnter() {
    this.cursorRef.style.display = `block`;
    window.addEventListener("mousemove", this.mouseMove);
  }
  
  mouseLeave() {
    this.cursorRef.style.display = `none`;
    window.removeEventListener("mousemove", this.mouseMove);
  }

  mouseMove(e) {
    const mouse = getMousePosition(e);
    this.cursorRef.style.left = `${mouse.x}px`;
    this.cursorRef.style.top = `${mouse.y}px`;
  }

  onClick() {
    this.setState({
      popup: true,
    })
  }

  render() {

    const { popup, mounted } = this.state;

    return (
      <>
        <div className={`content ${mounted && "content--visible"}`}>
          <p className={"content__copy"}>
            Oh! What's this? Another art show!?
          </p>
          <p className={"content__copy"}>
            Look at All These Things is happening again! From <span className={"underline"}>7-10pm, November 7th</span>, 2019. It's at the One Club.
          </p>
          <p className={"content__copy"}>
            Wait. Where? <span className="mobile-line-break"></span><span className={"underline"}>450 W 31st St.</span><br/>You should come. <span className="mobile-line-break"></span><a ref={r=>this.linkRef=r} className={"underline underline--link"} href="#" onClick={this.onClick}>Sign up here.</a>
          </p>
        </div>
        <img className={"content__cursor"} ref={r=>this.cursorRef=r} src={Cursor}></img>
        <Popup isVisible={popup} onClose={() => {
          this.setState({
            popup: false,
          })
        }}/>
      </>
    )
  }
}
export default Content
